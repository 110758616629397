<template>
  <div class="container">
    <h4>{{ $t("productos.grupos.nutricionClinicaAdultos.titulo") }}</h4>

    <span class="productoDetalle">
      {{ $t("productos.grupos.nutricionClinicaAdultos.subTitulo") }}
    </span>

    <div class="productGrid">
      <Producto
        imgFileName="Lata_SustentaNeutro.webp"
        :texto="$t('productos.sustentaNeutro.cardTitle')"
        link="SustentaNeutro"
      />

      <Producto
        imgFileName="Lata_SustentaVainilla.webp"
        :texto="$t('productos.sustentaVainilla.cardTitle')"
        link="SustentaVainilla"
      />

      <Producto
        imgFileName="Lata_SustentaDBNeutro.webp"
        :texto="$t('productos.sustentaDB.cardTitle')"
        link="SustentaDBNeutro"
      />

      <Producto
        imgFileName="Lata_SustentaDBVainilla.webp"
        :texto="$t('productos.sustentaDBVainilla.cardTitle')"
        link="SustentaDBVainilla"
      />

      <Producto
        imgFileName="Lata_SemielementalAdultos.webp"
        :texto="$t('productos.sustentaSemielementalAdultos.cardTitle')"
        link="SustentaSemielemental"
      />

      <Producto
        imgFileName="Lata_SustentaFibras.webp"
        :texto="$t('productos.sustentaFibras.cardTitle')"
        link="SustentaFibras"
      />

      <Producto
        imgFileName="Lata_Dessert.webp"
        :texto="$t('productos.sustentaDessert.cardTitle')"
        link="SustentaDessert"
      />
    </div>
  </div>
</template>

<script>
import Producto from "@/components/Producto.vue";

export default {
  name: "NutricionClinicaAdulta",
  components: {
    Producto,
  }
};
</script>