<template>
  <div class="producto pe-4 my-3">
    <div class="">
      <router-link :to="{name: link, params: {flia: familia} }">
        <img :src="getImage(imgFileName)" class="img-fluid product-image m-2" />
      </router-link>
    </div>

    <div v-if="texto !== ''" class="texto">
      {{ texto }}
    </div>

    <div class="p-2">
      <router-link class="info" :to="{name: link, params: {flia: familia} }">+ INFO</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Producto",
  props: {
    imgFileName: String,
    texto: String,
    link: String,
    familia:String
  },
  methods: {
    getImage(fileName) {
      return require("@/assets/images/productos/cards/" + fileName);
    },
  },
};
</script>
