<template>
  <div class="container" id="moduloProductoHangPack">
    <h4>{{ $t("productos.grupos.hangPack.titulo") }}</h4>

    <span class="productoDetalle">
      {{ $t("productos.grupos.hangPack.subTitulo") }}
    </span>

    <div class="productGrid">
      <Producto
        imgFileName="HangPack_SustentaNeutro.webp"
        :texto="$t('productos.hangPackSustentaNeutro.cardTitle')"
        link="HangPackSustentaNeutro"
      />

      <Producto
        imgFileName="HangPack_JuniorSinLactosa.webp"
        :texto="$t('productos.hangPackJuniorSinLactosa.cardTitle')"
        link="HangPackJuniorSinLactosa"
      />
      
      <Producto
        imgFileName="HangPack_JuniorSemielemental.webp"
        :texto="$t('productos.hangPackJuniorSemielemental.cardTitle')"
        link="HangPackJuniorSemielemental"
      />
      
      <Producto
        imgFileName="HangPack_SustentaSemielemental.webp"
        :texto="$t('productos.hangPackSustentaSemielemental.cardTitle')"
        link="HangPackSustentaSemielemental"
      />

      <!-- <div class="producto pe-4 my-3">
        <div class="">
          <img
            src="@/assets/images/productos/cards/HangPack_SustentaSemielemental.webp"
            class="img-fluid product-image m-2"
          />
        </div>

        <div class="texto">
          {{ $t("productos.hangPackSemielemental.cardTitle") }}
        </div>

        <div class="p-2 proximamente">{{ $t("cardsModulos.proximamente") }}</div>
      </div> -->

      <Producto
        imgFileName="HangPack_SustentaDBNeutro.webp"
        :texto="$t('productos.hangPackSustentaDBNeutro.cardTitle')"
        link="HangPackSustentaDBNeutro"
      />

      <!-- <div class="producto pe-4 my-3">
        <div class="">
          <img
            src="@/assets/images/productos/cards/HangPack_SustentaDBNeutro.webp"
            class="img-fluid product-image m-2"
          />
        </div>

        <div class="texto">
          {{ $t("productos.hangPackDBNeutro.cardTitle") }}
        </div>

        <div class="p-2 proximamente">{{ $t("cardsModulos.proximamente") }}</div>
      </div> -->

    </div>
  </div>
</template>

<script>
import Producto from "@/components/Producto.vue";

export default {
  name: "HangPack",
  components: {
    Producto,
  },
};
</script>