<template>
  <div class="container">
    <h4>{{ $t("productos.grupos.dietaCetogenica.titulo") }}</h4>

    <span class="productoDetalle">
      {{ $t("productos.grupos.dietaCetogenica.subTitulo") }}
    </span>

    <div class="productGrid">
      <Producto
        imgFileName="Lata_Cetogenik.webp"
        :texto="$t('productos.cetogenik.cardTitle')"
        link="Cetogenik"
      />

      <Producto
        imgFileName="Botellas_Oil.webp"
        :texto="$t('productos.mediumChainLipidsOil.cardTitle')"
        link="MediumChainLipidsOil"
        familia="dieta-cetogenica"
      />

      <Producto
        imgFileName="Lata_MediumChainLipids.webp"
        :texto="$t('productos.mediumChainLipids.cardTitle')"
        link="MediumChainLipids"
        familia="dieta-cetogenica"
      />
    </div>
  </div>
</template>

<script>
import Producto from "@/components/Producto.vue";

export default {
  name: "DietaCetogenica",
  components: {
    Producto,
  },
};
</script>