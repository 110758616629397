<template>
  <div class="container d-flex flex-column" ref="top" id="productos">
    <div ref="nutricion-clinica-adulta"></div>
    <NutricionClinicaAdulta class="mt-5" />
    <hr />

    <div ref="nutricion-clinica-pediatricos"></div>
    <NutricionClinicaPediatricos class="mt-3" />

    <hr />
    <div ref="modulos-nutricionales"></div>
    <ModulosNutricionales class="mt-3" />

    <hr />
    <div ref="dieta-cetogenica"></div>
    <DietaCetogenica class="mt-3" />

    <hr />
    <div ref="hangPack"></div>
    <HangPack class="mt-3 mb-5" />
  </div>
</template>

<script>
import NutricionClinicaAdulta from "@/views/ModulosProductos/_NutricionClinicaAdulta.vue";
import NutricionClinicaPediatricos from "@/views/ModulosProductos/_NutricionClinicaPediatricos.vue";
import ModulosNutricionales from "@/views/ModulosProductos/_ModulosNutricionales.vue";
import DietaCetogenica from "@/views/ModulosProductos/_DietaCetogenica.vue";
import HangPack from "@/views/ModulosProductos/_HangPack.vue";

export default {
  name: "Productos",
  components: {
    NutricionClinicaAdulta,
    NutricionClinicaPediatricos,
    ModulosNutricionales,
    DietaCetogenica,
    HangPack,
  },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        this.scrollToAnchorPoint(refName);
      }
    });
  },
  methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>