<template>
  <div class="container">
    <h4>{{ $t('productos.grupos.nutricionClinicaPediatricos.titulo') }}</h4>

    <span class="productoDetalle">
      {{ $t('productos.grupos.nutricionClinicaPediatricos.subTitulo') }}
      </span>

    <div class="productGrid">
      <Producto
        imgFileName="Lata_JuniorSinLactosa.webp"
        :texto="$t('productos.sustentaJunior.cardTitle')"
        link="SustentaJuniorSinLactosa"
      />

      <Producto
        imgFileName="Lata_JuniorSemielemental.webp"
        :texto="$t('productos.sustentaJuniorSemielemental.cardTitle')"
        link="SustentaJuniorSemielemental"
      />

      <Producto
        imgFileName="Lata_Lipidgen.webp"
        :texto="$t('productos.lipidgen.cardTitle')"
        link="Lipidgen"
      />
    </div>
  </div>
</template>

<script>
import Producto from "@/components/Producto.vue";

export default {
  name: "NutricionClinicaPediatricos",
  components: {
    Producto,
  },
};
</script>