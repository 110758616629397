<template>
  <div class="container">
    <h4>{{ $t("productos.grupos.modulosNutricionales.titulo") }}</h4>

    <span class="productoDetalle">
      {{ $t("productos.grupos.modulosNutricionales.subTitulo") }}
    </span>

    <div class="productGrid">
      <Producto
        imgFileName="Lata_EspesanteResist.webp"
        :texto="$t('productos.espesanteResist.cardTitle')"
        link="EspesanteResist"
      />

      <!-- <Producto
        imgFileName="Lata_EspesanteBlue.webp"
        :texto="$t('productos.espesanteBlue.cardTitle')"
        link="ProductoB"
      /> -->

      <Producto
        imgFileName="Lata_EspesanteXanthan.webp"
        :texto="$t('productos.espesanteXanthan.cardTitle')"
        link="EspesanteXanthan"
      />

      <Producto
        imgFileName="Botellas_Oil.webp"
        :texto="$t('productos.mediumChainLipidsOil.cardTitle')"
        link="MediumChainLipidsOil"
        familia="modulos-nutricionales"
      />

      <Producto
        imgFileName="Lata_MediumChainLipids.webp"
        :texto="$t('productos.mediumChainLipids.cardTitle')"
        link="MediumChainLipids"
        familia="modulos-nutricionales"
      />

      <Producto
        imgFileName="Lata_PoliCarb.webp"
        :texto="$t('productos.poliCarb.cardTitle')"
        link="PoliCarb"
      />

      <Producto
        imgFileName="Lata_Dextrosa.webp"
        :texto="$t('productos.dextrosa.cardTitle')"
        link="Dextrosa"
      />

      <Producto
        imgFileName="Lata_Lactoproteyn.webp"
        :texto="$t('productos.lactoproteyn.cardTitle')"
        link="Lactoproteyn"
      />

      <Producto
        imgFileName="Lata_Fibras.webp"
        :texto="$t('productos.fibras.cardTitle')"
        link="Fibras"
      />
    </div>
  </div>
</template>

<script>
import Producto from "@/components/Producto.vue";

export default {
  name: "ModulosNutricionales",
  components: {
    Producto,
  },
};
</script>
